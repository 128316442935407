export const techTags = [
  {
    id: 1,
    name: "Product Design",
  },
  {
    id: 3,
    name: "Front-End",
  },
  {
    id: 4,
    name: "Back-End",
  },
  {
    id: 5,
    name: "DevOps",
  },
  {
    id: 6,
    name: "QA",
  },
]

export const aboutSections = [
  {
    id: 1,
    heading: "Industry Vertical",
    description: "FinTech, GameTech",
  },
  {
    id: 2,
    heading: "Business Model",
    description: "B2C",
  },
  {
    id: 3,
    heading: "Our Work",
    description:
      "Product Design, Custom Software Development, DevOps, QA and Testing",
  },
]

export const techImages = [
  {
    id: 1,
    icon: "/caseStudy/Ugami/tech1.svg",
  },
  {
    id: 2,
    icon: "/caseStudy/Ugami/tech2.svg",
  },
  {
    id: 3,
    icon: "/caseStudy/Ugami/tech3.svg",
  },
  {
    id: 4,
    icon: "/caseStudy/Ugami/tech4.svg",
  },
  {
    id: 5,
    icon: "/caseStudy/Ugami/tech5.svg",
  },
  {
    id: 6,
    icon: "/caseStudy/Ugami/tech6.svg",
  },
  {
    id: 7,
    icon: "/caseStudy/Ugami/tech7.svg",
  },
  {
    id: 8,
    icon: "/caseStudy/Ugami/tech8.svg",
  },
  {
    id: 9,
    icon: "/caseStudy/Ugami/tech9.svg",
  },
  {
    id: 10,
    icon: "/caseStudy/Ugami/tech10.svg",
  },
  {
    id: 11,
    icon: "/caseStudy/Ugami/tech11.svg",
  },
  {
    id: 12,
    icon: "/caseStudy/Ugami/tech12.svg",
  },
  {
    id: 13,
    icon: "/caseStudy/Ugami/tech13.svg",
  },
  {
    id: 14,
    icon: "/caseStudy/Ugami/tech14.svg",
  },
]

export const aboutProjectIcons = [
  { id: 1, icon: "/caseStudy/Ugami/about1.svg" },
  { id: 2, icon: "/caseStudy/Ugami/about2.svg" },
  { id: 3, icon: "/caseStudy/Ugami/about3.svg" },
]

export const keyChallenges = [
  {
    id: 1,
    title: "Inadequate Reward System for Millennials",
    para: "Traditional loyalty programs fail to meet the needs of digitally-savvy millennials who demand streamlined and gamified financial solutions that offer real rewards.",
  },
  {
    id: 2,
    title: "Gap in the Market for Blockchain-Powered Loyalty Programs",
    para: "The emergence of blockchain-powered stablecoins has created a gap in the market that traditional fiat-based loyalty point programs cannot address.",
  },
  {
    id: 3,
    title: "Complexity in Traditional Loyalty Point Programs",
    para: "Traditional loyalty point programs can be burdensome and unrewarding, creating a barrier for users to engage and redeem rewards.",
  },
  {
    id: 4,
    title: "Difficulty in Retaining and Engaging Users",
    para: "Low engagement and retention rates are common in traditional loyalty programs due to complex user experience and a lack of incentive for users to stay loyal.",
  },
]

export const solutions = [
  {
    id: 1,
    heading: "Loyalty Points",
    description:
      "Next-generation loyalty points technology that bridges the gap between traditional fiat and blockchain-powered stablecoins",
    icon: "/caseStudy/Ugami/solution1.svg",
  },
  {
    id: 2,
    heading: "User-Friendly Platform",
    description:
      "A seamless, user-friendly, and engaging platform for earning and redeeming loyalty points that maximizes customer retention and satisfaction",
    icon: "/caseStudy/Ugami/solution2.svg",
  },
  {
    id: 3,
    heading: "Financial Reward System",
    description:
      "A gamified financial reward system that offers users the ability to earn real rewards backed by stablecoins",
    icon: "/caseStudy/Ugami/solution3.svg",
  },
  {
    id: 4,
    heading: "Laser-Focused Approach on Millennials",
    description:
      "A laser-focused approach on the millennial demographic, known to seek competitive edges in earning loyalty points",
    icon: "/caseStudy/Ugami/solution4.svg",
  },
]

export const featuresList = [
  {
    id: 1,
    feature: "Customizable Rewards",
    icon: "/caseStudy/Ugami/feature1.svg",
    icon2: "/caseStudy/Ugami/feature11.svg",
    image: "/caseStudy/Ugami/featurebg1.png",
    paragraph:
      "Users can choose from a variety of rewards that best suit their preferences and interests, making the platform highly customizable.",
  },
  {
    id: 2,
    feature: "Robust Security",
    icon: "/caseStudy/Ugami/feature2.svg",
    icon2: "/caseStudy/Ugami/feature12.svg",
    image: "/caseStudy/Ugami/featurebg2.png",
    paragraph:
      "The platform employs advanced security measures such as multi-factor authentication, data encryption, and secure access controls to ensure the protection of user data and assets.",
  },
  {
    id: 3,
    feature: "Real-time Analytics",
    icon: "/caseStudy/Ugami/feature3.svg",
    icon2: "/caseStudy/Ugami/feature13.svg",
    image: "/caseStudy/Ugami/featurebg3.png",
    paragraph:
      "The platform provides real-time analytics and reporting to help users track their progress and optimize their loyalty points earning strategies.",
  },
  {
    id: 4,
    feature: "Social Sharing",
    icon: "/caseStudy/Ugami/feature4.svg",
    icon2: "/caseStudy/Ugami/feature14.svg",
    image: "/caseStudy/Ugami/featurebg4.png",
    paragraph:
      "Users can share their rewards and achievements on social media platforms, creating a sense of community and encouraging engagement.",
  },
]

export const resultsList = [
  {
    id: 1,
    heading: "Improved Engagement",
    description: `The gamified financial rewards system and streamlined loyalty points program resulted in higher user engagement and retention rates`,
    icon: "/caseStudy/Ugami/result1.svg",
  },
  {
    id: 2,
    heading: "Bridging the Gap",
    description: `The project successfully bridged the gap between traditional fiat-based loyalty programs and blockchain-powered stablecoins, providing users with a powerful stablecoin-backed rewards system`,
    icon: "/caseStudy/Ugami/result2.svg",
  },
  {
    id: 3,
    heading: "Next-Generation Technology",
    description: `The project introduced a next-generation loyalty points technology that combined the best of both traditional and blockchain-based systems, providing users with a simplified and engaging user experience`,
    icon: "/caseStudy/Ugami/result3.svg",
  },
  {
    id: 4,
    heading: "Improved Customer Satisfaction",
    description: `The simplified and gamified loyalty points program resulted in improved customer satisfaction, as users were able to earn and redeem rewards more easily and enjoyably`,
    icon: "/caseStudy/Ugami/result4.svg",
  },
  {
    id: 5,
    heading: "Accessible to All",
    description: `The project made loyalty rewards accessible to everyone, including those who were previously unable to participate in traditional loyalty points programs`,
    icon: "/caseStudy/Ugami/result5.svg",
  },
]

export const businessNeedsList = [
  {
    id: 1,
    heading: "Enhanced Customer Loyalty",
    description: "",
    icon: "/caseStudy/Ugami/business1.svg",
  },
  {
    id: 2,
    heading: "Increased Customer Satisfaction",
    description: "",
    icon: "/caseStudy/Ugami/business2.svg",
  },
  {
    id: 3,
    heading: "Competitive Advantage",
    description: "",
    icon: "/caseStudy/Ugami/business3.svg",
  },
  {
    id: 4,
    heading: "Accessible to Everyone",
    description: "",
    icon: "/caseStudy/Ugami/business4.svg",
  },
  {
    id: 5,
    heading: "Improved Revenue",
    description: "",
    icon: "/caseStudy/Ugami/business5.svg",
  },
]
