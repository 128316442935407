import React, { useState } from "react"
import Hero from "../CaseStudy/Hero"
import AboutProject from "../CaseStudy/AboutProject"
import Features from "../CaseStudy/Features"
import TechStack from "../CaseStudy/TechStack"
import AppDisplayImage from "../CaseStudy/AppDisplayImage"
import KeyChallengaes from "../CaseStudy/KeyChallanges"
import Results from "../CaseStudy/Results"
import SolutionSection from "../CaseStudy/SolutionSection"
import BusinessNeeds from "../CaseStudy/BusinessNeeds"
import ContactUs from "../CaseStudy/Features/ContactUs"
import ReviewSection from "../CaseStudy/ReviewSection"
import {
  techTags,
  aboutSections,
  techImages,
  aboutProjectIcons,
  keyChallenges,
  solutions,
  featuresList,
  resultsList,
  businessNeedsList,
} from "./data"

function Ugami() {
  const [active, setActive] = useState(featuresList[0])

  const clickHandler = feature => {
    setActive(feature)
  }
  return (
    <div>
      <Hero
        techTags={techTags}
        aboutSections={aboutSections}
        title="Next-Generation Loyalty Points Technology"
        imageUrl="/caseStudy/Ugami/heroBg.png"
        logoUrl="/caseStudy/Ugami/heroLogo.svg"
        name="ugami"
      />
      <AboutProject
        text="The startup aims to transform the financial industry by creating a gamified and simplified approach to financial services for digital-savvy millennials. Their goal is to bridge the gap between traditional loyalty points programs and emerging blockchain-powered stablecoins."
        data={aboutProjectIcons}
      />
      <AppDisplayImage url="/caseStudy/Ugami/belowAbout.png" />
      <KeyChallengaes
        sideImage={"/caseStudy/Ugami/keyChallenges.png"}
        data={keyChallenges}
        showFullRightImage
        name="strada"
      />
      <AppDisplayImage url="/caseStudy/Ugami/belowKeyChallenges.png" />
      <SolutionSection data={solutions} />
      <AppDisplayImage url="/caseStudy/Ugami/belowSolution.png" />
      <Features
        data={featuresList}
        onClickHandler={clickHandler}
        active={active}
      />
      <Results data={resultsList} />
      <TechStack data={techImages} page="container-2" />
      <BusinessNeeds data={businessNeedsList} />
      <ReviewSection
        data={{
          review: `“They handle everything with flying colors.”`,
          clientName: "Gerald Bold",
          clientInfo: "Department Head, FinTech Company",
        }}
      />
      <ContactUs className="no-padding" />
    </div>
  )
}

export default Ugami
